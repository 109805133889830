<template>
    <Popover
        class="relative hidden lg:block">
        <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
            <span>Bundesländer</span>
            <Icon
                name="heroicons:chevron-down"
                class="h-3 w-3"
                aria-hidden="true" />
        </PopoverButton>

        <Transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel
                v-slot="{ close }"
                class="absolute z-50 mt-5 flex w-screen max-w-max lg:-translate-x-1/3 xl:-translate-x-1/4 px-4">
                <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm/6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-5xl">
                    <div class="relative bg-gray-50 hover:bg-gray-100 px-8 py-6">
                        <div class="flex items-center gap-x-3">
                            <NuxtLink
                                :to="localePath('/bundeslaender/uebersicht')"
                                class="text-sm/6 font-semibold text-gray-900"
                                @click="close">
                                Bundesländer - Übersicht
                                <span class="absolute inset-0" />
                            </NuxtLink>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-3 xl:grid-cols-4">
                        <KopfzeileBundesland
                            v-for="federalState in federalStates"
                            :key="federalState.id"
                            :federal-state="federalState"
                            @close="close" />
                    </div>
                </div>
            </PopoverPanel>
        </Transition>
    </Popover>
    <Disclosure
        v-slot="{ open }"
        as="div"
        class="-mx-3 block lg:hidden">
        <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
            Bundesländer
            <Icon
                name="heroicons:chevron-down"
                class="h-5 w-5 flex-none"
                :class="[open ? 'rotate-180' : '']"
                aria-hidden="true" />
        </DisclosureButton>
        <DisclosurePanel
            v-slot="{close}"
            class="mt-2 space-y-2">
            <NuxtLink
                :to="localePath('/bundeslaender/uebersicht')"
                class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50"
                @click="close && emits('closeMobileMenu')">
                Übersicht
            </NuxtLink>
            <NuxtLink
                v-for="fedState in federalStates"
                :key="fedState.id"
                :to="localePath('/bundeslaender/' + getFederalStateUrl(fedState))"
                class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50"
                @click="close && emits('closeMobileMenu')">
                {{ fedState.attributes?.Name }}
            </NuxtLink>
        </DisclosurePanel>
    </Disclosure>
</template>

<script setup>
import KopfzeileBundesland from "@/components/strapi/menu/KopfzeileBundeslaender/KopfzeileBundesland.vue";


const props = defineProps({
    component: {
        type: Object,
        required: true
    }
})

const emits = defineEmits(['closeMobileMenu'])

const {find} = useStrapi()

function getFederalStateUrl(federalState) {
    const Seite = federalState.attributes?.Seite?.data?.attributes
    let url = Seite?.url
    if (Seite?.Weiterleiten && Seite?.Unterseiten?.data?.length > 0) {
        url += '/' + Seite.Unterseiten.data[0].attributes.url
    }
    return url
}

const federalStates = ref(null)
const loading = ref(true)
await useAsyncData('bundeslands-menu', () => find('bundeslands', {populate: {Flagge: {fields: ['url']}, Seite: {fields: ['url', 'Weiterleiten'], populate: ['Unterseiten']}}}), {
    // this will not refetch if the key exists in the payload
    getCachedData: key => nuxtApp.payload.static[key] ?? nuxtApp.payload.data[key]
}).then(response => federalStates.value = response.data?.value?.data ?? {}).finally(() => loading.value = false)
</script>
